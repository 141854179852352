import React from 'react';
import SEO from '../../components/App/SEO';
import Layout from '../../components/App/Layout';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from 'gatsby';
const img = '/img/corporate/BlueMail_Corporate_Strip_Conditional_Access-1.png'

const conditionalAccessPage = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
        <SEO 
            postTitle='Conditional Access | BlueMail App' 
            postDescription='Conditional Access Take charge of your corporate mailboxes and ensure your team members have access to resources they need, and only the resources they need, with BlueMail Plus&#8217;s Conditional Access. Easily assign specific user groups to mailboxes from within your...'
            postImage={img}
            postURL='corporate/conditional-access'
            postSEO
        />
        <Navbar />
        <div className="conditional-access-area container pt-120 pb-100">
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center'}}>
                <img src={img} alt='BlueMail Corporate Conditional Access' style={{width: '100%'}}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center', marginTop: '1.8em'}}>
                    <h1>Conditional Access</h1>
                    <hr />
                </div>
                <div className='col-12'>
                <p className="mt-30 center-text">
                    Take charge of your corporate mailboxes and ensure your team members have access to resources they need, and only the resources they need, with BlueMail Plus’s Conditional Access. Easily assign specific user groups to mailboxes from within your Dashboard. No complicated Active Directory policies are necessary to quickly get your team the information they need.
                </p>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
)

export default conditionalAccessPage

export const query = graphql`
query ConditionalAccessPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`